@font-face {
    font-family: PrimaryFont;
    src: url('../fonts/font-1.ttf');
}
@font-face {
    font-family: SecondaryFont;
    src: url('../fonts/font-2.otf');
}


#v-nav-bar-btn {
    height: 45px;
    width: 45px;
    right: 12px;
    top: 10px;
    margin: 2px;
    z-index: 9;
    position: fixed;
}