
#horizontal-nav-bar {
    height: 100px;
    width: 100vw;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: rgb(40, 36, 30);
    display: flex;
    align-items: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    font-family: PrimaryFont;
    z-index: 10;
}


#h-nav-bar-logo {
    position: relative;
    height: auto;
    width: 100px;
    left: 5px;
}

#h-nav-bar-title {
    position: relative;
    left: 25px;
    top: 5px;
    font-size: 50px;
    font-family: inherit;
    color: rgb(244, 240, 232);
}

#h-nav-bar-title:hover {
    cursor: default;
}

#h-nav-bar-btns-div {
    position: absolute;
    height: 50px;
    float: right !important;
    top: 25px;
    right: 10px;
    width: 70%;
    overflow-y: hidden;
}

.h-nav-bar-btn {
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
    height: 40px;
    width: auto;
    font-size: 30px;
    font-weight: 900;
    color:rgb(244, 241, 235);
    text-decoration: none;
    float: right;
}

.h-nav-bar-btn:hover {
    cursor: pointer;
}