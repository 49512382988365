#main-div {
    width: 100vw;
    height: 550vh !important;
    top: 85px;
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    left: 0px;
    background-color: black;
}

.page-section {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: rgb(7, 7, 7);
    overflow: hidden;
}

#home-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(7, 7, 7);
}

#home-background-img {
    position: absolute;
    height: 100%;
    width: 110%;
    top: 0%;
    left: -5%;
    object-fit: cover;
    filter: blur(3px) contrast(40%) brightness(100%);
}

#home-logo {
    width: 86vw;
    left: 7vw;
    object-fit: contain;
    height: 65vh;
    z-index: 3;
    position: absolute;
    top: calc(25vh - 90px);
}

#about-us-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(22, 28, 52);
    height: 100vh !important;
    padding-bottom: 50px;
}

#about-us-div {
    position: relative;
    margin-left: 20px;
    background-color: rgb(61, 68, 101);
}

#about-us-from {
    width: 95%;
    text-align: right;
    font-family: PrimaryFont;
    font-size: 35px;
    margin-top: -5px;
}

.info-panel {
    height: min-content;
    width: min(450px, 40vw);
    
    border-radius: 10px;
    padding: 30px;
    color: rgb(244, 240, 232);
    font-family: PrimaryFont;
    overflow-y: scroll;
    margin: 20px;
    position: relative;
}

.info-panel > h2 {
    position: relative;
    top: -15px;
    font-size: 40px;
    margin-bottom: -10px;
}

.info-panel > p {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 17px;
    font-weight: 100;
    margin-bottom: 10px;
    line-height: 130%;
}

#gallery-section {
    display: flex;
    height: min-content;
    left: 0px;
    top: 0px;
    position: relative;
    justify-content: center;
    align-items: center;
}

#gallery-title {
    top: 6vh;
    width: 94%;
    left: 3%;
    position: absolute;
    z-index: 4;

    color: white;
    font-family: PrimaryFont;
    font-size: 65px;
}

#gallery-grid-div {
    background-color: black;
    height: 94%;
    width: 94%;
    top: calc(220px + 2vh);
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(280px, 100%), 1fr));
    justify-items: center;
    align-items: center;
    height: min-content;
    padding-bottom: 350px;
    gap: 3px;
}

#about-us-img {
    height: auto;
    width: min(550px, 50vw);
    position: relative;
    z-index: 5;
    gap: 5px;
}

#gallery-background-img {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    object-fit: cover;
    z-index: 2;
    filter: brightness(4%) saturate(120%) sepia(20%) blur(3px);
}

.gallery-image-div {
    z-index: 3;
    height: min-content;
    width: auto;
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    border: 0px;
    margin: 5px;
    border-radius: 5px;
}
.gallery-image-div:hover {
    cursor: grab;
}

.gallery-img {
    z-index: 4;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    object-fit:contain;
    position: relative;
    border-radius: 5px;
}



.gallery-desc {
    z-index: 4;
    left: 4%;
    top: calc(50% - 50px);
    height: 50px;
    width: 92%;
    position: absolute;
    color: rgb(244, 240, 232);
    visibility: hidden;
    font-family: cursive;
    text-align: center;
    font-size: 35px;
    font-family: PrimaryFont;
}

.gallery-desc:hover {
    cursor: grab;
}

#how-it-works-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(64, 76, 37);
    height: 100vh !important;
    padding-top: 30vh;
    padding-bottom: 30vh;
}

#how-it-works-div {
    position: relative;
    margin-left: 20px;
    color: rgb(244, 240, 232);
    background-color: rgb(91, 105, 66);
    overflow-y: hidden;
}

#how-it-works-imgs-div {
    height: auto;
    width: min(550px, 50vw);
    position: relative;
    z-index: 3;
    gap: 5px;
    display:grid;
    align-items: center;
    justify-content: center;
}
.how-it-works-img {
    position: relative;
    display: block;
    width: 90%;
    left: 5%;
    height: auto;
}


#how-it-works-div > h2 {
    font-size: 40px;
    left: -10px;
    margin-bottom: -30px;
}
#how-it-works-list > li {
    position: relative;
    list-style-type: none;
    left: -25px;
    height: 90px;
    margin: 5px;
}
#how-it-works-list > li > * {
    display: inline-block;
    position: absolute;
}
.step-number {
    margin-right: 15px;
    font-size: 25px;
    top: -15px;
    text-align: right;
}
.step-desc {
    position: relative;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
    font-size: 20px;
    width: calc(100% - 15px);
    left: 35px;
    top: -25px;
    height: min-content;
}

#contact-title {
    width: 94%;
    left: 3%;
    position: absolute;
    top: 6vh;
    font-family: PrimaryFont;
    font-size: 65px;
    color:rgb(244, 240, 232);
}

#contact-section {
    position: relative;
    height: 100vh;
    background-color: rgb(166, 144, 110);
    display: flex;
    justify-content: center;
    padding-bottom: calc(20vh + 200px);
}

#contact-info-div {
    position: relative;
    height: min(250px, 90vw);
    width: min(250px, 90vw);
    border-radius: 15px;
    background-color: rgb(127, 108, 80);
    top: 35vh;
    overflow: hidden;
}

#contact-info-div > h2 {
    position: relative;
    width: 110%;
    top: 0px;
    left: -5%;
    height: 80px !important;
    text-align: center;
    font-size: auto;
    margin-bottom: -25px;
    overflow-wrap: nowrap;
}

#contact-info-div > p {
    position: relative;
    width: 120%;
    text-align: center;
    left: -10%;
    margin-bottom: -20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;;
}

#socials-div {
    width: 100%;
    height: 60px;
    left: 0px;
    position: absolute;
    bottom: 0px !important;

    display: flex;
    justify-content: center;
    align-items: center;
}

#socials-div > a > img {
    background-color: rgb(166, 144, 110);
    height: 50px;
    width: 50px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 0px;
}

#socials-div > img:hover {
    cursor: grab;
}

#message-div {
    height: 400px;
    width: 300px;
    background-color: rgb(244, 240, 232);
    top: 27.5vh;
    overflow-y: hidden;
}

#message-div > h2 {
    position: relative;
    top: -30px;
    left: -10px;
    margin-bottom: -35px;
    font-size: 35px;
}

#message-div > * {
    color: black;
}

#message-div > form > label {
    font-size: 15px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
}

#message-div > form {
    height: 90%;
}

#message-div > form > input {
    position: relative;
    width: 96%;
    left: 2%;
    color:rgb(252, 251, 248);
    height: 20px;
    border: 0px;
    color: black;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#message-div > form > textarea {
    position: relative;
    width: 96%;
    left: 2%;
    height: 30% !important;
    color:rgb(252, 251, 248);
    border: 0px;
    color: black;
    resize: none;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#send-message-btn {
    border: 0px;
    background-color: rgb(78, 96, 53);
    height: 30px !important;
    width: 70px !important;
    color: rgb(252, 251, 248);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-radius: 3px;
    font-size: 15px;
}
#send-message-btn:hover {
    cursor: grab;
}

@media only screen and (max-width: 1200px) {
    #main-div {
        top: -5px;
        height: 200vh;
    }
    #home-section {
        height: calc(100vh + 5px);
    }
    #home-background-img {
        height: 105vh;
    }
    #home-logo {
        top: 20vh;
    }
    #gallery-title {
        top: 20px;
    }
    #gallery-grid-div {
        padding-bottom: 65vh;
    }
    #how-it-works-section {
        padding-top: 25vh;
        padding-bottom: 60vh;
    }
    #contact-section {
        padding-bottom: 80vh;
    }
}

@media only screen and (max-width: 750px) {
    .info-panel > h2 {
        font-size: 35px !important;
    }
    .info-panel > p {
        font-size: 18px !important;
    }
    #about-us-from {
        font-size: 30px !important;
    }
    #about-us-section {
        display: block;
        padding-bottom: 55vh;
    }
    .info-panel {
        display: block;
        position: relative;
        width: 86vw !important;
        padding: 10px;
        margin: 0px !important;
        left: calc(7vw - 10px);
        top: 10vh;
    }
    #gallery-title {
        text-align: center;
        font-size: 65px;
    }
    #gallery-grid-div {
        padding-bottom: 35vh;
        top: 175px;
    }
    #about-us-img {
        display: block;
        position: relative;
        width: 90vw;

        left: 5vw;
        margin: 0px;
        top: 20vh;
    }
    #about-us-div {
        top: 25vh;
    }
    #how-it-works-section {
        display: block;
        height: min-content !important;
    }
    #how-it-works-div {
        left: 4vw;
    }
    #how-it-works-imgs-div {
        top: 12vh;
        width: 94vw !important;
        left: 3vw !important;
        height: auto;
    }
    .step-desc {
        font-size: 18px;
        left: 20px;
        width: 73vw;
    }
    .step-number {
        left: -15px;
        font-size: 24px;
    }
    #how-it-works-list > li {
        margin-bottom: 10px;
    }
    #how-it-works-div > h2 {
        top: 10px;
        left: 5px;
        margin-bottom: 20px;
        font-size: 40px !important;
    }
    #how-it-works-section {
        padding-top: 0vh;
        padding-bottom: 20vh;
    }
    #how-it-works-div > h2 {
        font-size: 25px;
        left: 0px;
        text-align: center;
    }
    #contact-title {
        font-size: 45px;
        left: 0px;
        text-align: center;
    }
    #contact-info-div {
        top: 25vh;
        width: 84vw !important;
        height: 84vw !important;
        left: 5vw;
        display: block;
        position: relative;
        overflow-y: hidden;
    }
    #message-div {
        top: 30vh;
        width: 88vw !important;
        left: calc(50% - 46.5vw);
    }
    #message-div > h2 {
        font-size: 30px !important;
        position: relative;
        top: -25px;
        left: -5px;
    }
    #contact-section {
        display: block;
        height: min-content;
        padding-bottom: 80vh;
    }
    #contact-info-div > h2 {
        margin-bottom: 35px;
        top: 5vh;
        font-size: 40px !important;
    }
    #contact-info-div > p {
        margin-bottom: -15px !important;
        font-size: 15px !important;
    }
    #socials-div {
        bottom: -8vh;
    }
    
}