@keyframes navBarOpen {
    from {right: -210px}
    to {right: 0px}
}
@keyframes navBarClose {
    from {right: 0px}
    to {right: -210px}
}

#vertical-nav-bar {
    height: 100vh;
    width: 200px;
    position: fixed;
    top: 0px;
    background-color: rgb(40, 36, 30);
    display: flex;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    font-family: PrimaryFont;
    z-index: 10;
    overflow-y: scroll;

}

.open-anim {
    animation-name: navBarOpen;
    animation-duration: 0.5s;
}
.close-anim {
    animation-name: navBarClose;
    animation-duration: 0.5s;
}

.preload {
    animation-duration: 0s !important;
    animation-name: none;
    visibility: hidden !important;
}

#v-nav-bar-x {
    position: relative;
    height: 40px;
    width: 40px;
    left: 155px;
    top: -5px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
    color: white;
    font-size: 35px;
    text-align: center;

}

#v-nav-bar-x:hover {
    cursor: pointer;
}

.v-nav-bar-btn {
    text-decoration: none;
    position: relative;
    top: 70px;
    left: -15px;
    height: 50px;
    line-height: 50px;
    width: auto;
    font-size: 22px;
    font-weight: 900;
    font-style: italic;
    margin-bottom: 45px !important;
    color:rgb(244, 241, 235);
}

.v-nav-bar-btn:hover {
    cursor: pointer;
}