#root {
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    padding: 100px;
}

html {
    overflow-x: hidden;
    background-color: black;
}